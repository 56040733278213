var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { manageService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject, Debounce } from "~/core/decorator";
import { Service } from "~/core/service";
var DepartmentService = /** @class */ (function (_super) {
    __extends(DepartmentService, _super);
    function DepartmentService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 新增部门
     */
    DepartmentService.prototype.addDepartment = function (data, loading) {
        return this.netService.send({
            server: manageService.departmentController.addDepartment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 修改部门
     */
    DepartmentService.prototype.modifyDepartment = function (data, loading) {
        return this.netService.send({
            server: manageService.departmentController.modifyDepartment,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除部门
     */
    DepartmentService.prototype.deleteDepartment = function (departId, loading) {
        return this.netService.send({
            server: manageService.departmentController.deleteDepartment,
            data: {
                departId: departId,
            },
            loading: loading,
        });
    };
    /**
     * 获取全部部门
     */
    DepartmentService.prototype.findAllDepartment = function (loading) {
        return this.netService.send({
            server: manageService.departmentController.findAllDepartment,
            loading: loading,
        });
    };
    /**
     * 获取所有的分公司
     */
    DepartmentService.prototype.findAllBranch = function () {
        return this.netService.send({
            server: manageService.departmentController.findAllBranch,
            loading: true,
        });
    };
    /**
     * 获取部门下的调收员
     */
    DepartmentService.prototype.queryCollectorByDepartCode = function () {
        return this.netService.send({
            server: manageService.departmentController.queryCollectorByDepartCode,
        });
    };
    __decorate([
        Inject(NetService)
    ], DepartmentService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], DepartmentService.prototype, "addDepartment", null);
    __decorate([
        Debounce()
    ], DepartmentService.prototype, "modifyDepartment", null);
    __decorate([
        Debounce()
    ], DepartmentService.prototype, "deleteDepartment", null);
    __decorate([
        Debounce()
    ], DepartmentService.prototype, "findAllDepartment", null);
    return DepartmentService;
}(Service));
export { DepartmentService };
