var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { UserService } from "~/services/manage-service/user.service";
import { State, Getter } from "vuex-class";
import clone from "clone";
var PersonRoster = /** @class */ (function (_super) {
    __extends(PersonRoster, _super);
    function PersonRoster() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.submitLoading = false;
        _this.isHidden = false;
        _this.loading = {
            state: false
        };
        _this.callModel = {
            id: "",
            username: "",
            realName: "",
            station: "",
            isManage: 'NO',
            status: "WORKING",
            departId: "",
            certificateNo: "",
            age: "",
            sex: "UNKNOWN",
            birthplace: "",
            nation: "",
            politicalStatus: "",
            maritalStatus: "UNKNOWN",
            education: "",
            profession: "",
            school: "",
            graduationTime: "",
            joinWorkTime: "",
            phoneNo: "",
            mobileNo: "",
            email: "",
            experience: "",
            lastEmployer: "",
            emergencyContactName: "",
            emergencyContactNo: "",
            homeAddress: "",
            residenceAddress: "",
            entryTime: "",
            workAge: "",
            insurance: "",
            bank: "",
            bankCard: "",
            firstSignDate: "",
            secondSignDate: "",
            thirdSignDate: "",
            firstExpireDate: "",
            secondExpireDate: "",
            leaveDate: "",
            leaveReason: "",
            remark: "",
            channelNo: "" //通道号
        };
        _this.rules = {
            realName: [{ required: true, message: "请输入姓名" }],
            entryTime: [{ required: true, message: "请选择入职时间" }],
            username: [{ required: true, message: "请输入员工工号" }],
            departId: [{ required: true, message: "请选择所属机构" }],
            status: [{ required: true, message: "请选择用户状态" }],
            isManage: [{ required: true, message: "请选择是否管理员" }]
        };
        _this.deptIds = [];
        return _this;
    }
    PersonRoster.prototype.close = function () {
        this.reset();
    };
    PersonRoster.prototype.success = function () {
        this.close();
    };
    Object.defineProperty(PersonRoster.prototype, "modifyDataId", {
        get: function () {
            return this.modifyData.id ? true : false;
        },
        enumerable: false,
        configurable: true
    });
    PersonRoster.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length < 1) {
            return;
        }
        this.deptIds = valueArray;
        this.callModel.departId = valueArray[valueArray.length - 1];
    };
    PersonRoster.prototype.getDeptIds = function (id) {
        var dept = this.departmentList.find(function (x) { return x.id === id; });
        if (dept) {
            this.deptIds.unshift(id);
            if (dept.parent) {
                this.getDeptIds(dept.parent);
            }
        }
    };
    /**
     * 表单重置
     */
    PersonRoster.prototype.reset = function () {
        var form = this.$refs["userForm"];
        form.resetFields();
        this.callModel.id = "";
        this.deptIds = [];
    };
    /**
     * 查询用户详情
     */
    PersonRoster.prototype.refresh = function () {
        var _this = this;
        this.reset();
        if (this.modifyData.id) {
            this.userService.getUserDetail(this.modifyData.id, this.loading).subscribe(function (data) {
                _this.callModel = clone(data);
                _this.callModel.id = _this.modifyData.id;
                _this.getDeptIds(_this.modifyData.departId);
            });
            this.isHidden = false;
        }
        else {
            this.getDeptIds(this.deptId);
            this.callModel.departId = this.deptId;
            this.isHidden = true;
        }
    };
    PersonRoster.prototype.cancel = function () {
        this.close();
    };
    PersonRoster.prototype.submit = function () {
        var _this = this;
        var form = this.$refs["userForm"];
        form.validate(function (valid) {
            if (!valid)
                return false;
            _this.loading.state = true;
            _this.submitLoading = true;
            _this.callModel.organization =
                _this.deptIds.length > 0 ? _this.deptIds[_this.deptIds.length - 1] : "";
            if (_this.callModel.id) {
                _this.userService.modifyUser(_this.callModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("修改成功!");
                    _this.submitLoading = false;
                    _this.success();
                });
            }
            else {
                _this.userService.addUser(_this.callModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("新增成功!");
                    _this.submitLoading = false;
                    _this.success();
                });
            }
            _this.submitLoading = false;
        });
    };
    __decorate([
        Dependencies(UserService)
    ], PersonRoster.prototype, "userService", void 0);
    __decorate([
        State
    ], PersonRoster.prototype, "departmentList", void 0);
    __decorate([
        Getter
    ], PersonRoster.prototype, "departmentData", void 0);
    __decorate([
        Prop()
    ], PersonRoster.prototype, "modifyData", void 0);
    __decorate([
        Prop()
    ], PersonRoster.prototype, "deptId", void 0);
    __decorate([
        Emit()
    ], PersonRoster.prototype, "close", null);
    __decorate([
        Emit()
    ], PersonRoster.prototype, "success", null);
    PersonRoster = __decorate([
        Component({
            components: {}
        })
    ], PersonRoster);
    return PersonRoster;
}(Vue));
export default PersonRoster;
